import { FuelType } from '@/lib/schema/inventory/types';
import clsx from 'clsx';
import { FC } from 'react';
import Icon, { IconIds } from '../legacy/Icon';

interface ElectricityBarProps {
  evWidth: number;
  evCost: number;
  fuelType?: FuelType;
  white?: boolean;
}

export const ElectricityBar: FC<ElectricityBarProps> = ({
  evWidth,
  evCost,
  white = false,
  fuelType = 'Electric',
}) => {
  let iconId = white ? IconIds.ElectricityBarWhite : IconIds.ElectricityBar;
  if (fuelType === 'Hybrid') {
    iconId = white
      ? IconIds.ElectricityBarHybridWhite
      : IconIds.ElectricityBarHybrid;
  }

  return (
    <div className="flex flex-col gap-m">
      <div className="relative flex h-[54px] w-full">
        <div className="absolute left-0 top-0 z-10 flex h-[60px]">
          <Icon iconId={iconId} className="flex" />
        </div>
        <div
          className={clsx(
            'relative z-0 ml-[26px] flex min-w-max rounded-r-small py-l pl-[36px] pr-xl text-neutral-900',
            {
              'bg-electricity-bar': fuelType === 'Electric',
              'bg-electricity-bar-hybrid': fuelType === 'Hybrid',
            }
          )}
          style={{
            width: `max(${evWidth}%, 100px)`,
          }}
        >
          <span className="text-body2Medium">
            ${evCost.toLocaleString()}
            <span className="text-body2Light">/ year</span>
          </span>
          <div
            className={clsx(
              'absolute left-0 top-0 z-0 flex h-full w-full rounded-r-small',
              {
                'animate-neon-pulse shadow-neon-shadow':
                  fuelType === 'Electric',
                'animate-hybrid-pulse shadow-hybrid-shadow':
                  fuelType === 'Hybrid',
              }
            )}
          />
        </div>
      </div>
      <div>
        <span className="text-microMedium text-neutral-900">Electricity </span>
        <span className="text-microLight text-neutral-900">cost</span>
      </div>
    </div>
  );
};

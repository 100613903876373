import { STYLE_TO_EFFICIENCY } from '@/lib/constants';

interface FuelCostsInput {
  carType: string;
  electricityCost: number;
  gasolineCost: number;
  mileagePerYear: number;
  years: number;
  fuelType: 'Electric' | 'Hybrid';
}

const getCostPerYear = (mileage: number, cost: number, efficiency: number) => {
  return Math.round((mileage * cost) / efficiency);
};

export const getFuelCosts = ({
  carType,
  electricityCost,
  gasolineCost,
  mileagePerYear,
  years,
  fuelType,
}: FuelCostsInput) => {
  const body = carType in STYLE_TO_EFFICIENCY ? carType : 'Sedan';

  const gasC = Math.round(
    getCostPerYear(mileagePerYear, gasolineCost, STYLE_TO_EFFICIENCY[body].gas)
  );

  let altC = gasC * 0.8;
  if (fuelType === 'Electric') {
    const electricRate = 100 / STYLE_TO_EFFICIENCY[body].electric;
    altC = getCostPerYear(mileagePerYear, electricityCost, electricRate);
  }
  altC = Math.round(altC);

  const calculatedSavingsPerYear = gasC - altC;

  return {
    totalSavings: calculatedSavingsPerYear * years,
    savingsPerYear: calculatedSavingsPerYear,
    gasCost: gasC,
    altCost: altC,
  };
};
